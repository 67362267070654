<template>
  <b-card header-tag="header" header-text-variant="primary">
    <!-- HEADER -->
    <template v-slot:header>
      <b-card-title>
        Global Categories
        <!-- New -->
        <b-icon-plus-circle @click="addNew" scale="1.5" class="float-right" />
      </b-card-title>
    </template>

    <b-table striped hover :items="categories" :fields="fields">
      <template #cell(edit)="data">
        <b-icon-pencil-fill @click="editIconClicked(data.item)" />
      </template>
      <template #cell(delete)="data">
        <b-icon-trash-fill @click="deleteIconClicked(data.item)" />
      </template>
    </b-table>

    <!-- Modal to Add/Edit Global Category -->
    <b-modal size="lg" v-model="modalShow" hide-header hide-footer centered>
      <GlobalCategoryForm
        :category="category"
        @success="onSuccess"
        @cancel="onFormCancel"
      />
    </b-modal>
  </b-card>
</template>

<script>
import { deleteCategory, getCategories } from "../../../../apis/admin";

import GlobalCategoryForm from "../../../../components/categories/GlobalCategoryForm.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { GlobalCategoryForm },
  created() {
    // make api call to get categories
    getCategories().then((data) => {
      this.setCategories(data);
    });
  },
  data() {
    return {
      // modal based form
      modalShow: false,
      category: null,

      // table
      fields: [
        "id",
        "name",
        { key: "edit", label: "" },
        { key: "delete", label: "" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      categories: "admin/globalCategories/getList",
    }),
  },
  methods: {
    ...mapActions({
      setCategories: "admin/globalCategories/setList",
      removeCategory: "admin/globalCategories/removeItem",
    }),
    addNew() {
      this.modalShow = true;
      this.category = null;
    },
    editIconClicked(item) {
      this.modalShow = true;
      this.category = item;
    },

    onSuccess() {
      this.modalShow = false;
    },
    onFormCancel() {
      this.modalShow = false;
    },
    deleteIconClicked(item) {
      // show confirm modal to delete
      this.$bvModal
        .msgBoxConfirm("Confirm Delete?", {
          // title: "Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "secondary",
          okTitle: "YES",
          cancelVariant: "primary",
          cancelTitle: "NO",
          // footerClass: "p-2",
          centered: true,
        })
        .then((value) => {
          // on confirm delete, delete item
          if (value) {
            // delete item, on success delete, remove item from list
            deleteCategory(item.id).then(() => {
              this.removeCategory(item);
            });
          }
        });
    },
  },
};
</script>

<style></style>
